/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import 'swiper/scss';
@import '@ionic/angular/css/ionic-swiper';

@font-face {
    font-family: SF-UI-Display-Regular;
    src: url(/assets/fonts/SF-UI-Display-Regular.otf);
}

@font-face {
    font-family: SF-UI-Display-Medium;
    src: url(/assets/fonts/SF-UI-Display-Medium.otf);
}

@font-face {
    font-family: SF-UI-Display-Bold;
    src: url(/assets/fonts/SF-UI-Display-Bold.otf);
}

@font-face {
    font-family: NotoSans-Black;
    src: url(/assets/fonts/Noto_Sans/NotoSans-Black.ttf);
}

@font-face {
    font-family: NotoSans-ExtraBold;
    src: url(/assets/fonts/Noto_Sans/NotoSans-ExtraBold.ttf);
}

@font-face {
    font-family: NotoSans-Bold;
    src: url(/assets/fonts/Noto_Sans/NotoSans-Bold.ttf);
}

@font-face {
    font-family: NotoSans-Regular;
    src: url(/assets/fonts/Noto_Sans/NotoSans-Regular.ttf);
}

@font-face {
    font-family: NotoSans-Medium;
    src: url(/assets/fonts/Noto_Sans/NotoSans-Medium.ttf);
}

@font-face {
    font-family: Stratum-Black;
    src: url(/assets/fonts/Stratum-Black.otf);
}

@font-face {
    font-family: Stratum-Bold;
    src: url(/assets/fonts/Stratum-Bold.otf);
}

@font-face {
    font-family: Stratum-Light;
    src: url(/assets/fonts/Stratum-Light.otf);
}

@font-face {
    font-family: Stratum-Medium;
    src: url(/assets/fonts/Stratum-Medium.otf);
}

@font-face {
    font-family: Stratum-Regular;
    src: url(/assets/fonts/Stratum-Regular.otf);
}

@font-face {
    font-family: Stratum-Thin;
    src: url(/assets/fonts/Stratum-Thin.otf);
}

html.ios,
html,
ion-app,
p,
span {
    font-family: NotoSans-Regular;
}

h1,
h2,
h3,
h4,
h5,
h6,
ion-title {
    font-family: Stratum-Bold;
    // letter-spacing: 2px;
}

ion-button {
    font-family: Stratum-Bold;
}

ion-card-subtitle {
    font-family: Stratum-Medium;
}

ion-header {
    background: var(--content-bg);

    ion-toolbar {
        position: relative;
        --background: #fff;
        --border-width: 0 !important;
        --min-height: 50px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        ion-icon[name="notifications"] {
            color: var(--ion-color-primary);
        }

        ion-title {

            color: var(--ion-color-primary);
            font-size: 20px;
            text-transform: uppercase;
            font-weight: normal;
            // padding: 30px 0px;
        }

        ion-back-button {
            --icon-padding-top: 0px !important;
        }

        ion-icon {
            font-size: 28px;
            margin-right: 8px;
            // margin-top: 12px;
        }
    }
}

.primary {
    --background: var(--primary-btn-color);
    --background-activated: var(--primary-btn-color);
    --background-focused: var(--primary-btn-color);
    --background-hover: var(--primary-btn-color);
    height: 57px;
    max-width: 89%;
    margin: auto;
    margin-bottom: 20px;
    color: #fff;
    font-size: 20px;
    font-family: Stratum-Medium;
    text-transform: uppercase;
}

.outline {
    --background: var(--input-bg);
    --background-activated: var(--input-bg);
    --background-focused: var(--input-bg);
    --background-hover: var(--input-bg);
    color: var(--ion-color-primary);
    border: 2px solid var(--ion-color-primary);
    border-radius: 10px;
    height: 57px;
    max-width: 100%;
    margin: auto;
    margin-bottom: 20px;
    font-size: 20px;
}

app-login {
    .frame {
        top: 50px !important;
    }
}

app-primer-suggestion{
    .frame{
        top: 50px !important;
    }
}

.frame {
    background-color: #fff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    top: 170px;
    // margin-top: 40px;
    // height: calc(100vh - 120px);

    h1 {
        color: var(--ion-color-primary);
        margin-top: 32px;
        font-size: 26px;
        margin-bottom: 37px;
        font-family: Stratum-Bold;
        //  font-family: Stratum-Black !important;
    }

    ion-label {
        display: block;
        padding-left: 27px;
        margin-bottom: 6px;
        font-family: Stratum-Bold;
        font-size: 16px;
    }

    ion-item {
        --background: #f2f3f4;
        max-width: 88%;
        margin: auto;
        margin-bottom: 15px;
        border-radius: 8px;
        --padding-top: 4px;
        --padding-bottom: 11px;

        ion-icon {
            color: var(--ion-color-primary);
            margin-bottom: 11px;
            margin-right: 11px;
        }

        .eye {
            color: #a5acba;
            font-size: 25px;
            position: relative;
            top: 5px;
        }
    }

    .primary {
        margin-top: 17px;
    }

    p {
        font-size: 17px;
        padding-bottom: 30px;
        color: var(--text-color);
    }

    // ion-text{
    //     color: #f0a93d;

    //     ion-checkbox {
    //         --size: 22px;
    //         position: relative;
    //         top: 4px;
    //         margin-right: 4px;
    //     }
    // }
    .footer {
        margin-top: 36px;
        margin-bottom: 20px;

        ion-text {
            color: #4f525f;
        }

        ion-row {
            ion-col:first-child:after {
                content: "";
                border-right: 1px solid #4f525f;
                display: block;
                width: 1px;
                height: 30px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0%, -50%);
            }
        }
    }

    .dark {
        --background: var(--ion-color-secondary);
        --background-activated: var(--ion-color-secondary);
        --background-focused: var(--ion-color-secondary);
        --background-hover: var(--ion-color-secondary);
        height: 57px;
        max-width: 88%;
        margin: auto;
        margin-bottom: 20px;
        color: #fff;
    }
}

p.errp {
    color: var(--ion-color-danger) !important;
    padding-left: 25px;
    padding-bottom: 0;

    ion-icon {
        color: var(--ion-color-danger) !important;
        position: relative;
        top: 3px;
        margin-right: 4px;
    }
}

ion-content {
    --background: var(--content-bg);
}

.table-bordered {
    border: none;
}

table.table.table-bordered.table-fixed.monthview-datetable th {
    border: none !important;
}

table.table.table-bordered.table-fixed.monthview-datetable small {
    color: #dddddd !important;
    font-size: 15px;
}

.monthview-container {
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.event-detail-container {
    border-top: 2px darkgrey solid;
    display: none;
}

ion-searchbar {

    .searchbar-input-container {

        .searchbar-input {
            padding-inline-start: 0px !important;
        }

        .searchbar-search-icon {
            width: 42px;
        }
    }
}

ion-slides {
    .swiper-pagination-bullets {
        .swiper-pagination-bullet {
            background: transparent;
            border: 1px solid var(--ion-color-primary);

        }

        .swiper-pagination-bullet-active {
            border: 1px solid var(--ion-color-primary);
            background: var(--ion-color-primary);
        }
    }
}

/* ================  PWA css start  ===================== */

@media screen and (min-width:768px) {
    ion-title {
        text-align: center;
    }

    .search-item ion-searchbar {
        --box-shadow: 0 !important;
    }

    .pwa-grid-style {
        width: 650px;
        background: var(--ion-color-background-white);
        border-radius: 20px;
    }

    .pwa-grid-style .frame ion-label {
        padding-left: 42px;
    }

    .pwa-grid-style .frame {
        margin-top: 0px !important;
        border-radius: 20px;
    }

    .pwa-grid-style .frame h1 {
        margin-top: 25px;
        margin-bottom: 15px;
    }

    .pwa-grid-style .frame p {
        margin: 0;
    }

    .pwa-grid-style .error {
        padding-left: 40px !important;
    }

}

@media screen and (min-width:600px) {
    .email_modal img {
        width: 54% !important;
        margin-top: 0px !important;
    }

    .custom_modal img {
        width: 40% !important;
        margin-top: 0px !important;
    }
}

/* ================  PWA css End  ===================== */

// .table-bordered, thead th, tbody td{
//     border: none !important;
// }
// .table-bordered thead th small{
//     font-size: 16px;
//     color: var( --month-color);
// }
// .table-bordered tbody td{
//     color:var(--gradient); 
//     // font-weight: 600;
// }
.table-bordered tbody td.monthview-selected {
    background-color: transparent !important;
    color: var(--input-bg);
    // border-radius: 100px;
}

.table-bordered tbody td.monthview-current {
    background-color: transparent !important;
}

.table-bordered,
thead th,
tbody td {
    border: none !important;
}

.table-bordered thead th small {
    font-size: 15px;
    color: var(--month-color);
}

.table-bordered tbody td {
    color: var(--gradient);
    // font-weight: 600;
    font-size: 14px;
}

.table-bordered tbody td.monthview-selected {
    // background:var(--gradient); 
    color: var(--input-bg);
    position: relative;

    &::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--gradient);
        border-radius: 100px;

    }
}

.table-bordered tbody td.monthview-current {
    background-color: transparent !important;
}

@media screen and (min-width:768px) {
    .pwa-grid-size {
        width: 100%;
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;

    }
}

ion-footer {
    background: var(--content-bg) !important;

    @media screen and (min-width:768px) {
        .primary {
            max-width: 100% !important;
        }
    }
}



.ElementsApp .InputElement {
    font-size: 16px !important;
    color: var(--ion-color-black) !important;
    font-family: NotoSans-Regular !important;
}


// ion-content{
//     --keyboard-offset:200px !important;
//   }

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

.badge {
    position: absolute;
    right: 4px;
    z-index: 9;
    top: 7px;
}


app-forgot-password {
    .frame {
        top: 50px !important;
    }
}